<script>
  import FileUpload from 'primevue/fileupload';
  import AkLabel from '@components/general/AkLabel';
  import AkFilePreview from '@components/input/AkFilePreview';
  import prettyBytes from 'pretty-bytes';
  export default {
    components: {FileUpload, AkLabel, AkFilePreview},
    props: {
      modelValue: {},
      className: {
        type: String,
        required: false,
        default: 'col-md-4',
      },
      disabled: {
        type: Boolean,
        required: false,
        default: undefined,
      },
      multiple: {
        type: Boolean,
        required: false,
        default: undefined,
      },
      compact: {
        type: Boolean,
        required: false,
        default: false,
      },
      maxiCompact: {
        type: Boolean,
        required: false,
        default: false,
      },
      maxNbFile: {
        type: Number,
        required: false,
      },
      label: {
        type: String,
        required: false,
      },
      downloadFct: {
        type: Function,
      },
      uploadFct: {
        type: Function,
      },
      deleteFct: {
        type: Function,
      },
      accept: {
        type: String,
      },
      size: {
        type: String,
      },
    },
    watch: {
      currentValue: {
        handler: function (newType) {
          this.$emit('update:modelValue', newType);
        },
        deep: true,
      },
    },
    methods: {
      getSizeReadable(size) {
        if (null == size) return null;
        return prettyBytes(size);
      },
      downloadFile(file, isPreview) {
        // SI MD :  vient du serveurn on appelera la "downloadFunction", sinon on DL le blob
        if (null == file.md5) {
          if (isPreview) {
            this.previewFileObjectUrl = URL.createObjectURL(file);
            this.showPreview = true;
          } else {
            const url = window.URL.createObjectURL(new Blob([file]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', file.name);
            document.body.appendChild(link);
            link.click();
          }
        } else if (null != this.downloadFct) {
          let dlPromise = this.downloadFct(file, isPreview);
          if (isPreview) {
            dlPromise.then((urlObject) => {
              this.previewFileObjectUrl = window.URL.createObjectURL(urlObject);
              this.previewFileType = urlObject.type;
              this.showPreview = true;
              this.previewFileName = file.name;
            });
          }
        }
        // FicService.download(file, isPreview).catch(e => {
        //   if (e.response.status === 404) {
        //     this.reference.error("Le fichier n'existe pas !")
        //   }
        // });
      },
      selectFiles(event) {
        // TODO : gestion du multi
        if (event.files) {
          // console.log(event.files);
          let file = event.files[0];

          if (null != this.uploadFct) {
            let promises = [];
            promises.push(this.uploadFct(file));
            Promise.all(promises).then((res) => {
              event.files[0] = res.data;
              file.id = Math.random().toString(36).substring(2, 15)
              this.currentValue = res.data;
              this.$refs.uploader.clear();
            });
              //
              //this.currentValue = res.data;
              //this.$refs.uploader.clear();
          } else {
            file.id = Math.random().toString(36).substring(2, 15);
            this.currentValue = file;
            this.$refs.uploader.clear();
          }
        }
      },
      confirmDeleteFile(file) {
        this.fileAction = file;
        this.displayDeleteDialog = true;
      },

      //faire la delete fct
      deleteFile() {
        let promises = [];
        if (null != this.deleteFct) {
          promises.push(this.deleteFct(this.fileAction));
        }
        Promise.all(promises).then(() => {
          this.displayDeleteDialog = false;
          this.currentValue = null;
        });
      },
    },
    data() {
      return {
        fileAction: null,
        displayDeleteDialog: false,
        showPreview: false,
        previewFileObjectUrl: null,
        previewFileType: null,
        previewFileName: null,
      };
    },
    mounted() {},
    computed: {
      currentValue: {
        get: function () {
          return this.modelValue;
        },
        set: function (val) {
          this.$emit('update:modelValue', val);
        },
      },
      showAddButton() {
        return !this.disabled && !this.currentValue;
      },
      isXL() {
        return this.size == 'xl';
      },
      isCompact() {
        return this.compact == true && this.maxiCompact == false;
      },
      isMaxiCompact() {
        return this.maxiCompact == true;
      },
    },
  };
</script>

<template>
  <div class="header-fic-list" :class="this.className">
    <ak-label v-if="label">{{ this.label }}</ak-label>
    <div>
      <div class="form-row">
        <div class="col-md-12" v-if="currentValue">
          <div class="fic-container" :class="{'fic-container-xl': isXL, 'fic-container-s': isCompact, 'fic-container-xs': isMaxiCompact }">
            <i
              class="file-icon fa pointer"
              @click="downloadFile(currentValue, true)"></i>

            <div>
              <span v-if="!isMaxiCompact" class="pointer" @click="downloadFile(currentValue, true)">{{ currentValue.name }}</span>
              <span v-if="!isCompact && !isMaxiCompact">{{ getSizeReadable(currentValue.size) }}</span>
            </div>
            <div class="icon-div">
              <!--<i class="pi pi-download download" @click="downloadFile(currentValue, false)"></i>-->
              <i class="pi pi-times delete" style="color: #6366F1" v-if="!disabled" @click="confirmDeleteFile(currentValue)"></i>
            </div>
          </div>
        </div>
        <div class="col-md-3" v-show="showAddButton">
          <div style="padding: 10px !important">
            <FileUpload
              ref="uploader"
              name="uploader"
              mode="basic"
              :auto="true"
              chooseLabel="Ajouter un fichier"
              cancelLabel="Annuler"
              :multiple="multiple"
              :showUploadButton="false"
              :accept="accept"
              :customUpload="true"
              @select="selectFiles" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="displayDeleteDialog" header="Supprimer le fichier" :modal="true" :style="{width: '450px'}">
    <div class="confirmation-content">
      <i class="fe fe-alert-triangle mr-1" style="font-size: 2rem" />
      <span v-if="fileAction">Voulez-vous vraiment supprimer ce fichier ?</span>
      <div class="p-dialog-footer">
        <Button label="Non" icon="pi pi-times" class="p-button-text" @click="displayDeleteDialog = false"></Button>
        <Button label="Oui" icon="pi pi-check" class="p-button-text" @click="deleteFile"></Button>
      </div>
    </div>

  </Dialog>

  <!-- <Dialog
    v-model:visible="showPreview"
    :header="previewFileName"
    :modal="true"
    class="file-preview-dialog"
    :maximizable="true">
    <object :type="previewFileType" :data="previewFileObjectUrl" width="100%" style="min-height: 99%" />
  </Dialog> -->
  <ak-file-preview
    v-if="showPreview"
    v-model:visible="showPreview"
    :fileName="previewFileName"
    :fileData="previewFileObjectUrl"
    :fileType="previewFileType" />
  <OverlayPanel ref="op"> plop </OverlayPanel>
</template>

<script>
  export default {
    components: {},
    props: {
      modelValue: {},
      fileName: {type: String},
      visible: {type: Boolean, default: true},
      fileData: {type: [String, Object]},
      fileType: {type: String},
    },
    watch: {
      display(val) {
        this.$emit('update:visible', val);
      },
      visible(val) {
        this.display = val;
      },
    },
    methods: {
      downloadFile() {
        const link = document.createElement('a');
        link.href = this.fileData;
        link.setAttribute('download', this.fileName);
        document.body.appendChild(link);
        link.click();
      },
    },
    data() {
      return {
        display: false,
      };
    },
    mounted() {
      this.display = this.visible;
    },
    computed: {
      isTypeSupported() {
        if (null == this.fileType) return false;
        if (this.fileType.startsWith('image/')) return true;
        if (this.fileType.startsWith('application/pdf')) return true;
        if (this.fileType.startsWith('text/')) return true;
        return false;
      },
    },
  };
</script>

<template>
  <div>
    <Dialog
      v-model:visible="display"
      :header="fileName"
      :modal="true"
      class="file-preview-dialog"
      :class="{'file-preview-dialog-small': !isTypeSupported}"
      :maximizable="true">
      <template v-if="isTypeSupported">
        <object :type="fileType" :data="fileData" width="100%" style="min-height: 99%" />
      </template>
      <template v-else>
        <div style="justify-content: center">
          <Message :closable="false" severity="warn" :sticky="true" class="">
            L'aperçu n'est pas disponible pour ce type de fichier.
            <button class="btn btn-inverse-primary ml-2" @click="downloadFile">
              <i class="fa fa-download download"></i>
              Télécharger
            </button>
          </Message>
        </div>
      </template>
    </Dialog>
  </div>
</template>
